import * as actions from '../../actions/loginForm';
import { connect, ConnectedProps } from 'react-redux';
import { LoginForm } from '../../components/login-form/loginForm';

const mapDispatchToProps = {
  authenticateWithGoogle: actions.authenticateWithGoogle,
  authenticate: actions.authenticate,
  setEmail: actions.setEmail,
  setPassword: actions.setPassword,
  onClickSignup: actions.onClickSignup,
};

const mapStateToProps = (state) => ({
  initialized: state.loginForm.initialized,
  customTokenPassed: !!state.login.customToken,
  pendingRedirect: state.login.pendingRedirect,
  authenticationErrorCode: state.loginForm.authenticationErrorCode,
  authenticationErrorMessage: state.loginForm.authenticationErrorMessage,
  googleAuthenticateInProgress: state.loginForm.googleAuthenticateInProgress,
  authenticateInProgress: state.loginForm.authenticateInProgress,
  password: state.loginForm.password,
  email: state.loginForm.email,
  googleAuthenticationEnabled: state.loginForm.googleAuthenticationEnabled,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type LoginProps = ConnectedProps<typeof connector>;

const LoginFormContainer = connector(LoginForm);

export default LoginFormContainer;
