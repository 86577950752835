import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  Auth,
  getAuth,
  getMultiFactorResolver,
  getRedirectResult,
  GoogleAuthProvider,
  MultiFactorError,
  MultiFactorResolver,
  PhoneAuthProvider,
  RecaptchaVerifier,
  SAMLAuthProvider,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  UserCredential,
} from 'firebase/auth';
import Config from '../app/configuration';

const config = {
  apiKey: Config.FirebaseApiKey,
  authDomain: Config.FirebaseAuthDomain,
  databaseURL: Config.FirebaseDatabaseURL,
  projectId: Config.FirebaseProjectId,
  storageBucket: Config.FirebaseStorageBucket,
  messagingSenderId: Config.FirebaseMessagingSenderId,
  enableRecaptcha: Config.EnableRecaptcha,
};
const samlConfig = {
  ...config,
  authDomain: Config.FirebaseSamlAuthDomain,
};
const recaptchaContainerId = 'recaptcha-container';

export class Firebase {
  firebaseApp: FirebaseApp;
  samlFirebaseApp: FirebaseApp;
  auth: Auth;
  samlAuth: Auth;
  recaptchaVerifier: RecaptchaVerifier;
  phoneAuthProvider: PhoneAuthProvider;
  enableRecaptcha: boolean;

  constructor() {
    this.firebaseApp = initializeApp(config, 'default');
    this.samlFirebaseApp = initializeApp(samlConfig, 'saml');
    this.auth = getAuth(this.firebaseApp);
    this.samlAuth = getAuth(this.samlFirebaseApp);
    this.phoneAuthProvider = new PhoneAuthProvider(this.auth);
    this.enableRecaptcha = config.enableRecaptcha === 'true';
  }

  initRecaptchaVerifier = (callback?) => {
    if (!this.enableRecaptcha) {
      return;
    }

    const parameters = {
      size: 'invisible',
      callback: (token) => {
        if (callback) {
          callback(token);
        }
      },
    };

    this.recaptchaVerifier = new RecaptchaVerifier(this.auth, recaptchaContainerId, parameters);
  };

  executeRecaptchaVerifier = async () => await this.recaptchaVerifier.verify();

  clearRecaptcha = () => {
    if (!this.recaptchaVerifier) {
      return;
    }

    try {
      this.recaptchaVerifier.clear();
    } catch (e) {
      // ignore
    }

    if (window.grecaptcha) {
      window.grecaptcha.reset();
    }

    const recaptchaContainer = document.getElementById(recaptchaContainerId);
    const newRecaptchaContainer = document.createElement('div');
    newRecaptchaContainer.setAttribute('id', recaptchaContainerId);

    recaptchaContainer.parentNode.replaceChild(newRecaptchaContainer, recaptchaContainer);

    this.recaptchaVerifier = null;
  };

  resetRecaptcha = async () => {
    const widgetId = await this.recaptchaVerifier.render();

    window.grecaptcha.reset(widgetId);
  };

  signInWithEmailAndPassword = async (email: string, password: string) =>
    await signInWithEmailAndPassword(this.auth, email, password);

  getCurrentUserIdToken = async () => await this.auth.currentUser.getIdToken();

  getCurrentUserRefreshToken = () => this.auth.currentUser.refreshToken;

  getCurrentSamlUserIdToken = async () => await this.samlAuth.currentUser.getIdToken();

  getCurrentSamlUserIdTokenResult = async () => await this.samlAuth.currentUser.getIdTokenResult();

  getCurrentUserIdTokenResult = async () => await this.auth.currentUser.getIdTokenResult();

  setLanguageCode = (languageCode: string) => (this.auth.languageCode = languageCode);

  authenticateWithGoogle = async (): Promise<void> => {
    const provider = new GoogleAuthProvider();

    provider.addScope('https://www.googleapis.com/auth/userinfo.email');

    await signInWithRedirect(this.auth, provider);
  };

  getRedirectResult = async () => await getRedirectResult(this.auth);
  getGoogleCredentials = async (user: UserCredential | null) => GoogleAuthProvider.credentialFromResult(user);

  signInWithCustomToken = async (token: string): Promise<UserCredential> => {
    return await signInWithCustomToken(this.auth, token);
  };

  signInWithSaml = async (provider: SAMLAuthProvider): Promise<UserCredential> => {
    return await signInWithPopup(this.samlAuth, provider);
  };

  getMultiFactorResolver = (error: MultiFactorError): MultiFactorResolver => getMultiFactorResolver(this.auth, error);
}

const firebase = new Firebase();

export default firebase;
